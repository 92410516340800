import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import PersonIcon from "@mui/icons-material/Person";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { blueGrey } from "@mui/material/colors";
import { useState } from "react";
import { asklogPayment } from "../../data/integrations/AsklogPayment";
import { Alert, CircularProgress, FormHelperText } from "@mui/material";
import { set } from "lodash/fp";
import { useAppData } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
const textColor = blueGrey["A700"];

const style = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  maxWidth: 640,
  minHeight: 300,
  p: 5,
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
};

type RegisterCollabProps = {
  updateListCallback: Function;
};

const ActionBuyTests = ({ id }: any) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(`/checkout/${id}`);
      }}
    >
      clique aqui
    </Button>
  );
};

export function RegisterCollab({ updateListCallback }: RegisterCollabProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [collabName, setCollabName] = useState<string>("");
  const [collabEmail, setCollabEmail] = useState<string>("");
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [nameEmpty, setNameEmpty] = useState<boolean>(false);
  const { addMessage } = useAppData();
  const { id } = useParams();

  const stopPropagationForTab = (event: any) => {
    if (event.key === "Tab" || event.key.toUpperCase() === "C") {
      event.stopPropagation();
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    if (!collabName || !collabEmail) {
      setEmailEmpty(!collabEmail);
      setNameEmpty(!collabName);
      setTimeout(() => {
        setEmailEmpty(false);
        setNameEmpty(false);
      }, 3000);
      return;
    }
    setLoading(true);
    asklogPayment
      .createInvite(collabName, collabEmail, Number(id))
      .then(() => {
        setLoading(false);
        setOpen(false);
        updateListCallback();
        addMessage?.("Colaborador cadastrado com sucesso.");
      })
      .catch((error) => {
        setLoading(false);
        setOpen(false);
        if (error.response.data.code === "INVITATION_LIMITS_OVER") {
          addMessage?.(
            "Você não possui mais testes disponíveis. Caso deseje comprar mais testes",
            <ActionBuyTests id={1} />
          );
          return;
        }
        addMessage?.(
          "Erro ao cadastrar colaborador. Revise os dados e tente novamente!"
        );
      });
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          color: textColor,
        }}
      >
        <PersonIcon
          sx={{
            color: textColor,
            fontSize: 24,
            marginRight: 0.5,
          }}
        />
        Cadastro individual
      </Button>
      <Modal
        open={open}
        onKeyDown={stopPropagationForTab}
        onClose={handleClose}
        aria-labelledby="modal-cadastro-colaborador"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{
              mb: 4,
            }}
          >
            <strong>Cadastrar colaborador</strong>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel error={nameEmpty} htmlFor="ipt-name">
                  Nome completo
                </InputLabel>
                <OutlinedInput
                  id="ipt-name"
                  label="Nome completo"
                  type="text"
                  error={nameEmpty}
                  value={collabName}
                  onChange={(e) => setCollabName(e.target.value)}
                />
                {nameEmpty && (
                  <FormHelperText sx={{ color: "red" }}>
                    O campo Nome completo é obrigatório
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel error={emailEmpty} htmlFor="ipt-email">
                  E-mail
                </InputLabel>
                <OutlinedInput
                  id="ipt-email"
                  label="E-mail"
                  type="text"
                  error={emailEmpty}
                  value={collabEmail}
                  onChange={(e) => setCollabEmail(e.target.value)}
                />
                {emailEmpty && (
                  <FormHelperText sx={{ color: "red" }}>
                    O campo E-mail é obrigatório
                  </FormHelperText>
                )}
              </FormControl>
              <Box display="flex" justifyContent="flex-start">
                <LoadingButton
                  variant="contained"
                  size="large"
                  loading={loading}
                  onClick={handleSave}
                >
                  Cadastrar
                </LoadingButton>
              </Box>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
