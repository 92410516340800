import styled from "@emotion/styled";

export const ModalContent = styled.div`
  margin-bottom: 1.25rem;
  
  ul {
    margin-top: 0;
    padding: 0 0 0 1rem;
  }

`

export const FileUpload = styled.div`
  border: 1px dotted #868E96;
  border-radius: 16px;
  display: block;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  input[type="file"]::-webkit-file-upload-button{
    -webkit-appearance: none;
    display: none;
 }

`

export const FileUploadLabel = styled.label`
color: black;
 display: block;
 margin-bottom: 2rem;
 padding: 1rem;

  span {
    color: #868E96;
    display: block;
  }
  
`

export const FileUploadInput = styled.input`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
`