import { Box, Typography } from "@mui/material";
export const AskLogo = ({ size = "small" }: any) => {
  const isSmallLogo = size === "small";
  return (
    <Typography>
      <Box
        display="inline"
        fontSize={isSmallLogo ? "30px" : "40px"}
        color="#00386e"
        fontWeight="bold"
      >
        ASKKLOG
        <Box
          display="inline"
          fontSize={isSmallLogo ? "26px" : "36px"}
          color="#2c93f9"
          fontWeight="bold"
        >
          DEVEL
        </Box>
      </Box>
    </Typography>
  );
};
