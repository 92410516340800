import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { AskLogo } from "../../components";

export function SideBar() {
  return (
    <Grid item xs={12} md={4}>
      <Box
        mb={2}
        sx={{
          maxWidth: 240,
        }}
      >
        <AskLogo size="big" />
      </Box>
      <Typography>SUA SEGURANÇA É A NOSSA PRIORIDADE!</Typography>
      <Typography>
        Fique tranquilo, para a sua segurança, os dados do seu cartão não são
        armazenados por nós. Os dados da sua compra são criptografados de ponta
        a ponta e as informações são armazenadas em data centers homologados e
        com certificados PCI-DSS.
      </Typography>
    </Grid>
  );
}
