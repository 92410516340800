import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";

const CustomButton = styled(ButtonUnstyled)`
  background: none;
  border: none;
  font-family: "gilroy-semibold";
  font-size: 1rem;

  &.active {
    color: #495057;
    font-weight: bold;
  }
`;

interface Data {
  personEmail: string;
  responseLink: string;
  status: string;
  personName: string;
  surveyLink: string;
  more_options: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "personName",
    numeric: false,
    disablePadding: true,
    label: "Nome",
  },
  {
    id: "personEmail",
    numeric: true,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "responseLink",
    numeric: true,
    disablePadding: false,
    label: "Resultado",
  },
  {
    id: "surveyLink",
    numeric: true,
    disablePadding: false,
    label: "Acessar teste",
  },
  // {
  //   id: 'more_options',
  //   numeric: true,
  //   disablePadding: false,
  //   label: '',
  // },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <div>
      {numSelected > 0 ? (
        <Toolbar
          sx={{
            background: "white",
            borderTop: "1px solid",
            borderColor: "#E6E6E6",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 10000,
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selecionado
          </Typography>

          <Stack direction="row">
            <Tooltip title="Delete">
              <Button
                variant="text"
                color="error"
                sx={{
                  width: 205,
                }}
              >
                Excluir colaborador
              </Button>
            </Tooltip>
            <Tooltip title="Enviar teste">
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                  width: 155,
                }}
              >
                Enviar teste
              </Button>
            </Tooltip>
          </Stack>
        </Toolbar>
      ) : (
        <div></div>
      )}
    </div>
  );
};

type CollabListProps = {
  data: Array<any>;
};

export function CollabList({ data }: CollabListProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("personName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [listData, setListData] = React.useState(data);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = listData.map((n) => n.personName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleChangeList = (value: string) => {
    if (!value) {
      setListData(data);
      return;
    }

    setListData(
      data.filter(
        (x) =>
          x.personName.toLowerCase().includes(value.toLowerCase()) ||
          x.personEmail.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  // const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Stack direction="row">
          <CustomButton className="active">
            Todos ({listData.length})
          </CustomButton>
          <CustomButton className="active">
            Enviados ({listData.filter((x) => x.status === "enviado").length})
          </CustomButton>
          <CustomButton className="active">
            Visualizados (
            {listData.filter((x) => x.status === "visualizado").length})
          </CustomButton>
          <CustomButton className="active">
            Concluídos (
            {listData.filter((x) => x.status === "completado").length})
          </CustomButton>
        </Stack>
        <Box>
          <TextField
            id="search_"
            hiddenLabel
            placeholder="Pesquisar por nome ou e-mail"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              handleChangeList(e.target.value);
            }}
            variant="outlined"
            size="small"
            sx={{
              width: 315,
            }}
          />
        </Box>
      </Box>

      <Paper variant="outlined" sx={{ width: "100%", mb: 2 }}>
        <Box px={3} py={2}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={listData.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(listData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    //  const isItemSelected = isSelected(row?.personName?.toString());
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row?.personName?.toString())}
                        role="checkbox"
                        //aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        //selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          { <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> }
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ color: "rgba(0, 0, 0, 0.70);" }}
                        >
                          {row.personName}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "rgba(0, 0, 0, 0.70);" }}
                        >
                          {row.personEmail}
                        </TableCell>
                        <TableCell
                          sx={{ color: "rgba(0, 0, 0, 0.70);" }}
                          align="right"
                        >
                          {row.status}
                        </TableCell>
                        {!!row.responseLink ? (
                          <TableCell
                            align="right"
                            onClick={() =>
                              window.open(row.responseLink.toString(), "_blank")
                            }
                          >
                            <Button>Acessar resultado</Button>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ color: "rgba(0, 0, 0, 0.70);" }}
                            align="right"
                          >
                            {row.responseLink || "-"}
                          </TableCell>
                        )}

                        <TableCell
                          align="right"
                          onClick={() =>
                            window.open(row.surveyLink.toString(), "_blank")
                          }
                        >
                          <Button>Acessar Teste</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Box>
  );
}
