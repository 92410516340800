import React from 'react';

import Snackbar from "@mui/material/Snackbar";

import {useAppData} from "../hooks";

// @ts-ignore
export interface AppMessage {
  id: string;
  text: string;
  time?: number;
  action?: React.ReactNode
}

export function Messages() {
  const { messages } = useAppData();

  return (
    <>
      {
        messages.map(({ id, text, time = 3000, action= ''}) => (
          <Snackbar
            key={id}
            open={true}
            autoHideDuration={time}
            message={text}
            action={action}
          />
        ))
      }
    </>
  )
}
