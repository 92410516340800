import * as React from 'react';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {RegisterCollab} from './RegisterCollab';
import {RegisterMultipleCollab} from './RegisterMultipleCollab';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface ProductHeaderProps {
  quantity: number;
  updateListCallback: Function;
}

export function ProductHeader({ quantity, updateListCallback }: ProductHeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      mb={3}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            marginBottom: 2,
          }}
        >
          <strong>
            <Link
              href="/"
              color="inherit"
              sx={{
                textDecoration: "none",
              }}
            >
              <ArrowBackIcon
                sx={{
                  fontSize: 28,
                  marginRight: 1,
                  marginLeft: -4.5,
                  position: "relative",
                  top: 6,
                }}
              />
              Meus Produtos
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" component="p" color='#00386e' sx={{ fontWeight: 900 }}>
                  ASKKLOG
                </Typography>
                <Typography variant="h5" component="p" color='#2c93f9'>
                  DEVEL
                </Typography>
              </Box>
            </Link>{" "}
          </strong>
        </Typography>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5" component="h2">
            <strong>{quantity} teste{quantity > 1 ? 's' : ''} disponíve{quantity > 1 ? 'is' : 'l'}</strong>
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Cadastrar colaborador
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple>
            <RegisterCollab updateListCallback={updateListCallback}/>
          </MenuItem>
          <MenuItem disableRipple disabled={true}>
            <RegisterMultipleCollab />
          </MenuItem>
        </StyledMenu>
      </Box>
    </Box>
  );
}
