import axios, { Axios } from "axios";
import Config from "../../config";

export class AsklogPayment {
  private client: Axios;

  constructor() {
    this.client = this.createHttpClient();
  }

  createHttpClient() {
    const headers: any = {
      "Content-Type": "application/json",
    };

    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      headers["Authorization"] = authToken;
    }

    return axios.create({
      baseURL: Config.asklogPayment.domain,
      headers,
    });
  }

  reload() {
    this.client = this.createHttpClient();
  }

  async purchase(data: any) {
    return await this.client.post("/transaction/purchase", data);
  }

  async listSuccessfulTransactions(code: string) {
    return await this.client.get("/purchase/list", {
      params: { customerCode: code },
    });
  }

  async registerUser(externalId: string, email: string) {
    return await this.client.post("/user/register", { externalId, email });
  }

  async getActivePurchase() {
    const response: any = await this.client.get("/purchase/active");
    return response.data.result;
  }

  async listProducts() {
    const response: any = await this.client.get("/products/list");
    return response.data.result;
  }

  async getProductDetails(id: number) {
    const response: any = await this.client.get(`/products/details/${id}`);
    return response.data.result;
  }

  async listInvites() {
    const response: any = await this.client.get("/invitation/list");
    return response.data.result;
  }

  async createInvite(name: string, email: string, productId: number) {
    const response: any = await this.client.post("/invitation/prepare", {
      name: name,
      email: email,
      productId: productId,
    });
    return response.data.result;
  }

  async validateCoupon(coupon: string) {
    return (await this.client.get(`/coupons/validate/${coupon}`)).data;
  }
}

export const asklogPayment = new AsklogPayment();
