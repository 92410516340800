import {useCallback} from "react";
import {asklogPayment} from "../data/integrations/AsklogPayment";

export function useMiddlewareApi() {
  const registerUser = useCallback(async (externalId: string, email: string, fallback?: () => any) => {
    try {
      return await asklogPayment.registerUser(externalId, email);
    } catch (e) {
      fallback?.();
      return null;
    }
  }, []);

  const updateUser = useCallback(() => {

  }, []);

  return { registerUser, updateUser };
}
