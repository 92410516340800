import React from 'react';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";

interface PaymentTypeProp {
  selected: string;
}

export function PaymentType({ selected }: PaymentTypeProp) {
  return (
    <Box mb={3}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="creditCard"
        >
          <FormControlLabel value="creditCard" control={<Radio />} label="Cartão de crédito" />
          {/* <FormControlLabel value="boleto" control={<Radio disabled />} label="Boleto" /> */}
          {/* <FormControlLabel value="pix" control={<Radio disabled />} label="Pix" /> */}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
