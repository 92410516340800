import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dashboard from '../../layout/Dashboard';
import { HistoricItem } from "./HistoricItem";
import { asklogPayment } from "../../data/integrations/AsklogPayment";
import { AppContext } from "../../contexts/AppContext";

export default function Historic() {
  const loaded = useRef(false);

  const [purchases, setPurchases] = useState([]);

  const { user } = useContext(AppContext);

  const loadPurchases = useCallback(async (code: string) => {
    try {
      const { data } = await asklogPayment.listSuccessfulTransactions(String(code));
      setPurchases(data.result);
    } catch (e) {
      setPurchases([])
    }
  }, []);

  useEffect(() => {
    if (!loaded.current && user?.sub) {
      loaded.current = true;
      loadPurchases(user?.sub);
    }
  }, [user?.sub, loaded.current]);

  return (
    <>
      <Dashboard>
        <Box
          mb={3}
        >
          <Typography
            variant="h4"
            component="h1"
          >
            <strong>Minhas compras</strong>
          </Typography>
        </Box>

        <Box mb={4}>
          {
            purchases.map(
              purchase => (
                <HistoricItem item={purchase} />
              )
            )
          }
          { !purchases.length && (<p>Você ainda não possui nenhuma compra.</p>)  }
        </Box>
      </Dashboard>
    </>
  )
}
