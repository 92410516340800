import React, { useCallback, useEffect, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';

import Dashboard from '../../layout/Dashboard';
import { CardProduct, Product } from '../../components';
import { asklogPayment } from '../../data/integrations/AsklogPayment';

export default function Home() {
  const loaded = useRef(false);

  const [activePurchase, setActivePurchase] = useState<any>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  const loadActivePurchase = useCallback(async () => {
    setLoading(true);
    try {
      const data = await asklogPayment.getActivePurchase();
      const resp = await asklogPayment.listProducts();

      setProducts(resp);
      // setActivePurchase(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadActivePurchase();
    }
  }, [loaded.current]);

  return (
    <Dashboard>
      <Grid container spacing={4}>
        {products.map((product: any, index: number) => (
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <CardProduct
              product={product}
              activePurchase={activePurchase}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>
    </Dashboard>
  );
}
