import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {RegisterCollab} from './RegisterCollab';
import {RegisterMultipleCollab} from './RegisterMultipleCollab';

type BoxRegisterCollabProps = {
  updateListCallback: Function
}

export function BoxRegisterCollab({updateListCallback}: BoxRegisterCollabProps) {
  return (
    <Box mb={4}>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "15px",
        }}
      >
        <Box px={5} py={4} sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
        }}>
          <Stack 
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <ErrorOutlineIcon
              color="primary"
              sx={{
                fontSize: 48,
              }}
            />
            <Typography
              color="primary"
              component="h2"
              variant="h4"
            >
              <strong>Você não possui  colaboradores cadastrados</strong>
            </Typography>
            <Typography
              variant="body1"
            >
              Cadastre um ou mais colaboradores para poder enviar o teste
            </Typography>
            <Stack direction="row" spacing={2}>
              <RegisterCollab updateListCallback={updateListCallback}/>
              <RegisterMultipleCollab />
            </Stack>
          </Stack>
        </Box>

      </Paper>
    </Box>
  );
}