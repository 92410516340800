import React from 'react';

import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { numberToReal } from '../../utils/money';
import { Product } from '../../components';

interface SelectQtyProps {
  onChange(qty: number): void;
  qty: number;
  product: Product | undefined;
}

export function SelectQty({ onChange, qty, product }: SelectQtyProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "")
    onChange(Number(value));
  };

  return (
    <Box mb={6}>
      <TableContainer>
        <Table aria-label="Meus produtos">
          <TableHead
            sx={{
              backgroundColor: '#f7f7f7',
            }}
          >
            <TableRow>
              <TableCell>
                <Typography>Produto</Typography>
              </TableCell>
              <TableCell>
                <Typography>Quantidade de testes</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <strong>Total</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography>{product?.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <FormControl fullWidth size="small">
                    {/* <InputLabel id="demo-simple-select-label">Quantidade</InputLabel> */}
                    <TextField inputProps={{ type: 'text' }}   value={qty.toLocaleString('pt-BR')} onChange={handleChange} />

                  </FormControl>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <strong>
                    {numberToReal((product?.uniPrice || 1) * qty)}
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
