import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";

import { CardBox } from "../CardBox";
import CircularProgress from "@mui/material/CircularProgress";
import { numberToReal } from "../../utils/money";
import { AskLogo } from "..";

export interface Product {
  id: number;
  name: string;
  description: string;
  uniPrice: number;
  minQuantity: number;
}

export function CardProduct({
  loading,
  activePurchase,
  product,
}: {
  loading: boolean;
  activePurchase: boolean;
  product: Product;
}) {
  const navigate = useNavigate();
  return (
    <CardBox>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems={"center"}
        marginTop={4}
      >
        <Box />
        <AskLogo />
        <Box
          // align="center"
          px={6}
          py={3}
        >
          <Typography variant="h5" component="p" textAlign={"center"}>
            {product.name}
          </Typography>
        </Box>
        <Divider />
        <Box px={4} py={2}>
          <Typography variant="body2" component="p" align="center">
            {product.description}
          </Typography>
        </Box>
        <Divider />
        <Box px={4} pt={2} pb={3}>
          <Stack mb={2}>
            <Typography variant="body2" component="p" align="center">
              a partir de
            </Typography>
            <Typography variant="h5" component="h2" align="center">
              {`${numberToReal(product.uniPrice)}`}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              !loading &&
              navigate(
                activePurchase
                  ? `/askklog-dev/${product.id}`
                  : `/checkout/${product.id}`
              )
            }
            disabled={loading}
            fullWidth
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: "#FFF",
                  fontSize: "10px",
                }}
              />
            ) : activePurchase ? (
              "Ver meu produto"
            ) : (
              "Comprar produto"
            )}
          </Button>
        </Box>
      </Box>
    </CardBox>
  );
}
