import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../contexts/AppContext";
import { asklogPayment } from "../../data/integrations/AsklogPayment";
import { Product } from "../../components";
import { debounce } from "lodash";
import { useAppData } from "../../hooks";

export function useCheckoutControl() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { addMessage } = useAppData();

  const { user } = useContext(AppContext);

  const [coupon, setCoupon] = useState<any>();
  const [status, setStatus] = useState("idle");
  const [product, setProduct] = useState<Product>();
  const [statusMessage, setStatusMessage] = useState<string[]>([]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [numberTests, setNumberTests] = useState(product?.minQuantity || 0);
  const [identification, setIdentification] = useState({
    name: "",
    streetAddress: "",
    numberAddress: "",
    additionalAddress: "",
    zipAddress: "",
    cityAddress: "",
    stateAddress: "",
  });
  const [creditCardData, setCreditCardData] = useState({
    number: "",
    holderName: "",
    expiresAt: "",
    document: "",
    phone: "",
    ddd: "",
    cvc: "",
  });

  const getDetails = async (id: number) => {
    const data = await asklogPayment.getProductDetails(id);
    setProduct(data);
  };

  const [paymentType, setPaymentType] = useState("credit_card");

  useEffect(() => {
    if (id) {
      getDetails(+id);
    }
    setStatus("idle");
  }, []);

  const onChangeIdentification = (field: string, value: string) => {
    setIdentification((data) => ({ ...data, [field]: value }));
  };

  const onChangeCreditCardData = (field: string, value: string) => {
    setCreditCardData((data) => ({ ...data, [field]: value }));
  };

  const handleChangeCoupon = async (e: any) => {
    setCoupon("");

    const { value } = e.target;
    if (value) {
      try {
        const result = await asklogPayment.validateCoupon(value);
        setCoupon(result);
      } catch (error) {
        addMessage?.("Cupom inválido");
      }
      return;
    }
  };

  const handleDebouceChange = useCallback(
    debounce(handleChangeCoupon, 2000),
    []
  );

  const clearFieldValue = (value: string, separator = " ") => {
    return value
      .split(" ")
      .filter((w) => w.length)
      .join(separator);
  };

  const purchase = useCallback(
    async (productId: number) => {
      setStatusMessage([]);

      setStatus("loading");
      try {
        const {
          data: { status },
        } = await asklogPayment.purchase({
          type: "credit_card",
          productQuantity: numberTests,
          productId: productId,
          customerName: user?.name,
          customerEmail: user?.email,
          customerType: "individual",
          customerCode: user?.sub,
          coupon: coupon?.code,
          supplyData: {
            number: clearFieldValue(creditCardData.number, ""),
            holderName: clearFieldValue(creditCardData.holderName),
            code: creditCardData.cvc,
            document: creditCardData.document,
            phone: creditCardData.phone,
            documentType: creditCardData.document.length > 11 ? "cnpj" : "cpf",
            ddd: creditCardData.ddd,
            expireAt: creditCardData.expiresAt,
            address: identification,
          },
        });

        console.log(status);

        if (status !== "successful") {
          setStatus("error");
        } else {
          setStatus("success");
          setStatusMessage([]);

          navigate("/checkout/success");
        }
      } catch (e: any) {
        setStatus("error");
        console.log(e);
        setStatusMessage([e.response.data.message]);
      }
    },
    [numberTests, creditCardData, user, coupon]
  );

  const totalAmount = numberTests * (product?.uniPrice || 0);

  const getTotalAmountWithDiscount = () => {
    const percentage = (100 - coupon.discountPercentage) / 100;

    return totalAmount * percentage;
  };

  return useMemo(
    () => ({
      product,
      data: {
        qty: numberTests,
        creditCard: creditCardData,
        totalAmount: totalAmount,
        coupon: coupon,
        discountValue: (totalAmount * coupon?.discountPercentage) / 100,
        totalWithDiscount: coupon?.discountPercentage
          ? getTotalAmountWithDiscount()
          : totalAmount,
        paymentType,
        acceptTerms,
        identification,
      },
      onChange: {
        identification: onChangeIdentification,
        creditCard: onChangeCreditCardData,
        qty: setNumberTests,
        paymentType: setPaymentType,
        setAcceptTerms,
        handleChangeCoupon: handleDebouceChange,
      },
      actions: {
        purchase,
      },
      status: {
        error: status === "error",
        success: status === "success",
        loading: status === "loading",
        statusMessage,
      },
    }),
    [
      product,
      numberTests,
      identification,
      paymentType,
      creditCardData,
      status,
      statusMessage,
      acceptTerms,
      coupon,
    ]
  );
}
