import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import GroupIcon from '@mui/icons-material/Group';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  ModalContent,
  FileUpload,
  FileUploadLabel,
  FileUploadInput,
} from "./styled";

import { blueGrey } from '@mui/material/colors';
const textColor = blueGrey['A700'];

const style = {
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '16px',
  maxWidth: 640,
  minHeight: 300,
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
};

export function RegisterMultipleCollab() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button disabled={true} onClick={handleOpen} sx={{
        color: textColor,
      }}>
        <GroupIcon
          sx={{
            color: textColor,
            fontSize: 24,
            marginRight: .5,
          }}
        />
        Cadastro em lote
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            <strong>Cadastrar em lote</strong>
          </Typography>
          
          <ModalContent>
            <p>Faça o download da <Link href="#" target="_blank">planilha de exemplo</Link> e preencha como nas instruções abaixo:</p>
            <ul>
              <li>Todas as colunas devem estar presentes no arquivo: NOME, SOBRENOME, EMAIL.</li>
              <li>Se precisar de ajuda, solicite a equipe de TI da sua empresa para realizar o preenchimento na planilha.</li>
            </ul>
          </ModalContent>
          
          {/* Esconder após o usuário clicar em continuar para realizar o envio */}
          <FileUpload>
            <FileUploadLabel htmlFor="upload" className="file-upload__label">
              Procurar e enviar arquivo
              <span>Tamanho Máx.: 5000 linhas.</span>
            </FileUploadLabel>
            <FileUploadInput id="upload" className="file-upload__input" type="file" name="file-upload" />
          </FileUpload>
          {/* Esconder após o usuário clicar em continuar para realizar o envio */}

          {/* Apresentar feedback após upload do arquivo */}
          <Stack sx={{ width: '100%' }} spacing={2} mb={4}>
            <Alert severity="success">10 cadastros foram realizados com sucesso!</Alert>
            <Alert severity="error">10 registros foram ignorados, pois já existem</Alert>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Verificar erros</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ModalContent>
                  <ul>
                    <li>thiago@gmail.com</li>
                    <li>brendon@gmail.com</li>
                    <li>renato@gmail.com</li>
                  </ul>
                </ModalContent>
              </AccordionDetails>
            </Accordion>
          </Stack>
          {/* Apresentar feedback após upload do arquivo */}

          <Box
            display="flex"
            justifyContent="flex-start"
          >
            <Button
              variant="contained"
              size="large"
            >
              Continuar
            </Button>
            
            {/* Apresentar Loading após envio do arquivo */}
            {/* <LoadingButton
              loading
              loadingPosition="start"
              variant="outlined"
              size="large"
              startIcon={<SaveIcon />}
              >
              Enviando
            </LoadingButton> */}
            {/* Apresentar Loading após envio do arquivo */}
            
          </Box>

        </Box>
      </Modal>
    </div>
  );
}