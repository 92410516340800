import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";

import Config from "../config";

interface AuthenticationProviderProps {
  children: React.ReactNode;
}


export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  return (  
    <Auth0Provider
      domain={Config.auth0.domain}
      clientId={Config.auth0.clientId}
      audience={Config.auth0.audience}
      redirectUri={window.location.origin}
      scope="update:users update:users_app_metadata update:current_user_metadata read:users read:current_user read:user_idp_tokens"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
