import React from "react";
import InputMask from "react-input-mask";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

interface IdentificationProps {
  onChange(fieldName: string, value: any): void;
  data: any;
}

export function Identification({ onChange, data }: IdentificationProps) {

  const onChangeFullName = (e: any) => onChange("name", e.target.value);
  const onChangeStreetAddress = (e: any) =>
    onChange("streetAddress", e.target.value);
  const onChangeNumberAddress = (e: any) =>
    onChange("numberAddress", e.target.value);
  const onChangeAdditionalAddress = (e: any) =>
    onChange("additionalAddress", e.target.value);
  const onChangeZipAddress = (e: any) => onChange("zipAddress", e.target.value);
  const onChangeCityAddress = (e: any) =>
    onChange("cityAddress", e.target.value);
  const onChangeStateAddress = (e: any) =>
    onChange("stateAddress", e.target.value);

  return (
    <>
      <Typography variant="h4" mb={1}>
        Endereço da cobrança
      </Typography>
      <Typography variant="body2" mb={3}>
      <strong>Atenção:</strong> É necessário o preenchimento de todos os campos com (*).
      </Typography>

      {/* <Grid container mb={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="ipt-name"
              label="Nome completo"
              variant="outlined"
              onChange={onChangeFullName}
              required
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid> */}

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <TextField
              id="ipt-address"
              label="Endereço (logradouro)"
              variant="outlined"
              onChange={onChangeStreetAddress}
              required
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              id="ipt-number"
              label="Número"
              variant="outlined"
              onChange={onChangeNumberAddress}
              required
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={3} display={'flex'} alignItems={'center'}>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <TextField
              id="ipt-address-complement"
              label="Complemento"
              variant="outlined"
              onChange={onChangeAdditionalAddress}
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth margin='none'>
            <InputMask mask="99999-999" maskChar={null} onChange={onChangeZipAddress} style={{margin:'0'}}>
              <TextField
                id="ipt-cep"

                label="CEP"
                margin="normal"
                fullWidth
                autoComplete="cep"
                variant="outlined"
                required
                // error
                // helperText="Campo obrigatório"
              />
            </InputMask>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <TextField
              id="ipt-city"
              label="Cidade"
              variant="outlined"
              onChange={onChangeCityAddress}
              required
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              id="ipt-uf"
              label="Estado"
              variant="outlined"
              onChange={onChangeStateAddress}
              required
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
