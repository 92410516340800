import { useState } from "react";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import masterLogo from "../../assets/master_card_logo.svg";
import eloLogo from "../../assets/elo_card_logo.svg";
import visaLogo from "../../assets/visa_card_logo.svg";
import defaultLogo from "../../assets/default_credit_card.svg";
import InputMask from "react-input-mask";
import { InputCreditCard } from "./styled";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface CreditCardFormProps {
  onChange(fieldName: string, value: any): void;
  data: any;
  isLoading?: boolean;
}

function cc_format(value: string) {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
}

export function CreditCardForm({
  onChange,
  data,
  isLoading,
}: CreditCardFormProps) {
  const [documentType, setDocumentType] = useState<string>("CPF");
  const [logoCreditCard, setLogoCreditCard] = useState<string>(defaultLogo);

  const onChangeNumber = (e: any) => {
    const { value } = e.target;
    switch (value[0]) {
      case "4":
        setLogoCreditCard(visaLogo);
        break;
      case "5":
        setLogoCreditCard(masterLogo);
        break;
      case "6":
        setLogoCreditCard(eloLogo);
        break;
      default:
        setLogoCreditCard(defaultLogo);
    }
    onChange("number", value ? cc_format(value.match(/\d+/g)?.join("")) : "");
  };

  const onChangeHolderName = (e: any) => {
    const { value } = e.target;
    if (!!value.match(/[0-9]/) || value.length > 50) return;
    
    onChange("holderName", value);
  };
  const onChangeExpiresAt = (e: any) =>
    onChange(
      "expiresAt",
      e.target.value
        ? e.target.value
            .match(/\d+/g)
            .join("")
            .replace(/(\d{1,2})(\d{2})(\d*)/, "$1/$2")
        : ""
    );
  const onChangeCvc = (e: any) =>
    onChange(
      "cvc",
      e.target.value
        ? e.target.value
            .match(/\d+/g)
            .join("")
            .replace(/(\d{3,5})(\d*)/, "$1")
        : ""
    );

  const onChangeDocument = (e: any) => {
    const { value } = e.target;
    onChange("document", value.replace(/[.\-/]/g, ""));
  };

  const onChangePhone = (e: any) =>
    onChange(
      "phone",
      e.target.value
        ? e.target.value.match(/\d+/g).join("").replace(/\D/g, "").slice(0, 11)
        : ""
    );

  const onChangeDdd = (e: any) =>
    onChange(
      "ddd",
      e.target.value
        ? e.target.value.match(/\d+/g).join("").replace(/\D/g, "").slice(0, 2)
        : ""
    );

  return (
    <>
      <InputCreditCard>
        <div className="ImageCreditCard">
          <img src={logoCreditCard} alt="Master Card" />
        </div>
        <input
          type="text"
          placeholder="Número do cartão"
          disabled={isLoading}
          onChange={onChangeNumber}
          value={data.number}
        />
        <div className="CodeCreditCard">
          <input
            type="text"
            placeholder="MM/AA"
            onChange={onChangeExpiresAt}
            disabled={isLoading}
            value={data.expiresAt}
          />
          <input
            type="text"
            placeholder="CVC"
            maxLength={3}
            onChange={onChangeCvc}
            disabled={isLoading}
            value={data.cvc}
          />
        </div>
      </InputCreditCard>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="ipt-card-name"
              label="Nome impresso no cartão"
              variant="outlined"
              onChange={onChangeHolderName}
              disabled={isLoading}
              value={data.holderName}
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              aria-label="options"
              name="document type"
              value={documentType}
              row
              onChange={(e) => setDocumentType(e.target.value)}
            >
              <FormControlLabel value="CPF" control={<Radio />} label="CPF" />
              <FormControlLabel value="CNPJ" control={<Radio />} label="CNPJ" />
            </RadioGroup>
            {
              <InputMask
                mask={
                  documentType === "CPF"
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                maskChar={null}
                onChange={onChangeDocument}
                value={data.document}
                disabled={isLoading}
                style={{ margin: "0" }}
              >
                <TextField
                  id="ipt-card-name"
                  label={`${documentType} do titular`}
                  variant="outlined"

                  // error
                  // helperText="Campo obrigatório"
                />
              </InputMask>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <TextField
              id="ipt-card-name"
              label="DDD"
              variant="outlined"
              onChange={onChangeDdd}
              value={data.ddd}
              disabled={isLoading}
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={10}>
          <FormControl fullWidth>
            <TextField
              id="ipt-card-name"
              label="Telefone do titular"
              variant="outlined"
              onChange={onChangePhone}
              value={data.phone}
              disabled={isLoading}
              // error
              // helperText="Campo obrigatório"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
