const Config = {
  auth0: {
    clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
    domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
  },
  asklogPayment: {
    domain: String(process.env.REACT_APP_ASKLOG_PAYMENT_URL),
  },
};

export default Config;
