import Dashboard from '../../layout/Dashboard';
import {ProductHeader} from './ProductHeader';
import {BoxRegisterCollab} from './BoxRegisterCollab';
import {CollabList} from './CollabList';
import {useCallback, useEffect, useRef, useState} from "react";
import {asklogPayment} from "../../data/integrations/AsklogPayment";
import {CircularProgress, Grid} from "@mui/material";

export default function AskklogDev() {
  const loaded = useRef(false);

  const [activePurchase, setActivePurchase] = useState<any>(null);
  const [invites, setInvites] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const listInvites = useCallback(async () => {
    setLoading(true);
    try {
      const inviteList = await asklogPayment.listInvites();
      setInvites(inviteList);
    } catch (e) {
      setInvites([]);
    }
  }, []);

  const updateList = async () => {
    setLoading(true);
    try {
      const inviteList = await asklogPayment.listInvites();
      setInvites(inviteList);
    } catch (e) {
      setInvites([]);
    }
    setLoading(false);
  }

  const loadActivePurchase = useCallback(async () => {
    setLoading(true);
    try {
      const data = await asklogPayment.getActivePurchase();
      await listInvites();
      setActivePurchase(data);
    } catch (e) {
      setActivePurchase(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadActivePurchase();
    }
  }, [loaded.current]);

  

  return (
    <Dashboard>
      { loading && (<Grid display="flex" justifyContent="center"><CircularProgress /></Grid>) }
      {
        !loading && (
          <>
            <ProductHeader quantity={activePurchase?.quantity - invites.length} updateListCallback={updateList} />
            {
              !invites.length ? <BoxRegisterCollab updateListCallback={updateList}/> : <CollabList data={invites} />
            }
          </>
        )
      }
    </Dashboard>
  )
}
