import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useAuth0, User} from "@auth0/auth0-react";

import { Auth0 } from "../data/integrations/Auth0";
import {AppMessage} from "../components";
import {useMiddlewareApi} from "../hooks";

type AppUser = User & { workersQty: number, companyName: string };

interface AppContextValue {
  user?: AppUser | null;
  updateUser?: (userId: string, data: Partial<AppUser>) => Promise<any>;
  messages: AppMessage[];
  addMessage?: (text: string, action?: React.ReactNode) => void;
}

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = React.createContext<AppContextValue>({ messages: [] });

export function AppContextProvider({ children }: AppContextProviderProps) {
  const [user, setUser] = useState<AppUser| null>(null);
  const [messages, setMessages] = useState<AppMessage[]>([]);
  const { user: userBase, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const auth0 = useRef(Auth0.init(getAccessTokenSilently));
  const api = useMiddlewareApi();

  const loadUser = useCallback(async () => {
    if (!user) {
      const metadata = await auth0.current.getUserMetadata(String(userBase?.sub)) || {};
      const {
        name,
        email,
        email_verified,
        id,
        workers_qty: workersQty,
        company_name: companyName,
      } = metadata;
      setUser({
        ...userBase,
        ...{ workersQty, companyName },
        id: id || userBase?.id,
        name: name || userBase?.name,
        email: email || userBase?.email,
        emailVerified: !!email_verified || userBase?.email_verified,
        metadata,
      });
    }
  },[userBase, user]);

  const updateUser = useCallback(async (userId: string, newData: Partial<AppUser>) => {
    try {
      await auth0.current.updateUser(userId, newData);
      await loadUser();
    } catch (e) {
      setUser({ ...user as any });
    }
  },[user]);

  const removeMessageById = useCallback((id: string) => {
    setMessages(items => items.filter(item => item.id !== id));
  }, []);

  const addMessage = useCallback((text: string, action?: ReactNode) => {
    const id = Math.random().toString(16).slice(2);
    setMessages(items => [...items, { id, text, action }]);
    setTimeout(() => removeMessageById(id), 4000);
  }, []);

  useEffect(() => {
    const registerUser = async (externalId: string, email: string, metadata: any) => {
      const { data } = await api.registerUser(externalId, email) || {};
      if (data) {
        await updateUser(externalId, { user_metadata: {
          id: data.result.id, ...metadata
        }});
      }
    }

    if (!user?.id && user?.sub && user?.email) {
      registerUser(user.sub, user.email, user.metadata)
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && userBase) {
      (async () => await loadUser())();
    }
  }, [userBase, isAuthenticated]);

  return (
    <AppContext.Provider value={{ user, updateUser, messages, addMessage }}>
      {children}
    </AppContext.Provider>
  )
}
