// import React from 'react';
import { useNavigate } from "react-router-dom";
import { theme } from '../../styles/theme';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {SuccessPage} from "../../layout";
import succesIcon from '../../assets/check-circle.svg'

export function CheckoutSuccess() {
  const navigate = useNavigate();
  return (
    <SuccessPage>
      <Box>
        <img
          src={succesIcon}
          alt="Pedido de compra enviado com sucesso!"
        />
      </Box>
      <Typography
        color={theme.palette.primary.main}
        component="h1"
        variant="h4"
      >
        <strong>
          Parabéns! <br /> Você adquiriu o seu teste Askklog
        </strong>
      </Typography>
      <Typography>Após a confirmação do pagamento, você terá acesso ao teste.</Typography>
      <Typography>No botão abaixo você será levado para a página de testes.</Typography>
      <Box>
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/historic")}
        >
          Ver meu produto
        </Button>
      </Box>
    </SuccessPage>
  )
}
